// 申请试用

// 申请使用增加
export function addProbations(data) {
  return axios({
    url: `/api/probations`,
    method: "post",
    data,
  });
}
