<template>
  <!-- 申请使用 -->
  <div>
    <!-- 产品介绍 -->
    <product-brief
      :height="currentData && currentData.productBrief.height"
      :title="currentData && currentData.productBrief.title"
      :memo="currentData && currentData.productBrief.memo"
      :img-src="currentData && currentData.productBrief.imgSrc"
    />

    <!-- 注册表单 -->
    <template v-if="isRegForm">
      <horizontal-bar
        v-for="(item, index) in currentData && currentData.horizontalBars"
        :key="index"
        :grayTitle="item.grayTitle"
        :title="item.title"
        :subTitle="item.subTitle"
        :hiddenTitle="item.hiddenTitle"
        :barStyle="item.style"
      >
        <partner-form
          :showTitle="false"
          :form="form"
          @validSuccess="validSuccess"
        />
      </horizontal-bar>
    </template>

    <!-- 注册成功 -->
    <reg-success v-else />
  </div>
</template>

<script>
import partnerForm from "@/components/local/partner-form";
import regSuccess from "./components/reg-success";

import { addProbations } from "@/api/applyTrial";

export default {
  components: {
    partnerForm, // 注册表单-组件
    regSuccess, // 注册成功
  },
  data() {
    return {
      form: {},
      currentData: {
        productBrief: {
          height: 180,
          title: "申请使用",
          memo: [""],
          imgSrc: "https://s1.ax1x.com/2020/07/27/aPrZxf.png",
        }, // 产品介绍组件数据
        horizontalBars: [
          {
            hiddenTitle: true,
            partnerForm: true,
          },
        ], // 横幅内容
      }, // 渲染数据
      isRegForm: true, // 是否表单页
    };
  },
  created() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.form = {
      ...userInfo,
      createdAt: null,
      updatedAt: null,
      state: null,
      target: this.$route.query?.fileName,
      mobile: userInfo.mobile.replace("+86", ""),
    };
  },
  methods: {
    validSuccess(form) {
      if (!this.$store.state.isLogin) {
        this.$store.commit("changeShowLogin", true);
      } else {
        addProbations(form).then((res) => {
          this.isRegForm = false;
        });
      }
    }, // 验证通过
  },
};
</script>

<style></style>
